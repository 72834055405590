<template>
	<div
		class="skeleton"
		:class="[dark && 'dark', roundness]"
		:style="`width: ${dimensions.width}; height: ${dimensions.height}; animation-delay: ${animationDelay || 0}ms;`"
	/>
</template>

<script>
import { defineComponent } from "vue";

import sizes from "./definitions/sizes.js";

function parseDimension(dimension) {
	switch (typeof dimension) {
		case "number":
			return `${dimension}px`;
		case "string":
		default:
			if (dimension.includes("%")) {
				return parseInt(dimension.replace("%", "")) <= 100 ? dimension : "100%";
			} else if (dimension.includes("px")) {
				return dimension;
			} else {
				return `${dimension}px`;
			}
	}
}

export default defineComponent({
	name: "TnSkeleton",

	props: {
		/**
		 * Animation delay in milliseconds
		 * @type Number
		 * */
		animationDelay: {
			type: Number,
		},
		/**
		 * Defaults to pixel-value. If percentage (%) is used with string, 100% is max.
		 * @type String, Number
		 * */
		width: {
			type: [Number, String],
			default: null,
			required: true,
		},
		/**
		 * Defaults to pixel-value. If percentage (%) is used with string, 100% is max.
		 * @type String, Number
		 * */
		height: {
			type: [Number, String],
			default: null,
		},
		/** True, False */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * s, m, l, square if not specified
		 * @values s, m, l
		 */
		borderRadius: {
			type: String,
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * @values True, False
		 */
		rounded: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		roundness() {
			if (this.rounded) {
				return "border-radius-full";
			} else if (this.borderRadius) {
				return `border-radius-${this.borderRadius}`;
			} else {
				return "";
			}
		},
		dimensions() {
			if (this.rounded) {
				return {
					width: parseDimension(this.height),
					height: parseDimension(this.height),
				};
			}
			return {
				width: parseDimension(this.width),
				height: parseDimension(this.height),
			};
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/all" as mixins;

.skeleton {
	background-color: variables.$color-neutrals-100-tint;

	@include mixins.animate-pulse;

	&.dark {
		background-color: variables.$color-neutrals-900-shade;
	}

	&.border-radius {
		&-s {
			border-radius: variables.$border-radius-s;
		}

		&-m {
			border-radius: variables.$border-radius-m;
		}

		&-l {
			border-radius: variables.$border-radius-l;
		}

		&-full {
			border-radius: 50%;
		}
	}
}
</style>
